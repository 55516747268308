import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import styled from "styled-components";
import { respondTo } from "../_respondTo";
import Swal from "sweetalert2";
import { FaBuilding, FaPhone, FaEnvelope } from "react-icons/fa";

import { Slide } from "react-reveal";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const FormInput = styled(Field)`
  display: block;
  color: white;
  background-color: rgba(255, 255, 255, 0);
  border-style: none;
  border-bottom: 2px solid white;
  width: 100%;
  height: 2em;
  font-family: sans-serif;
`;

const FormLabel = styled.label`
  display: block;
  text-decoration: underline;
  margin-top: 0.75em;
`;

const FormErrorMessage = styled(ErrorMessage)`
  display: block;
  color: red;
`;

const FormButton = styled.button`
  color: white;
  display: inline-block;

  text-decoration: none;
  padding: 0.5em;
  border-radius: 0.25em;
  font-family: sans-serif;
  background-color: #041dd7;

  margin: auto;

  border-style: none;
  margin-top: 2em;

  cursor: pointer;

  &:hover {
    background-color: #132ffb;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${respondTo.xsmall`
    flex-direction: row;
  
  `}
`;

const url = typeof window !== "undefined" ? window.location.href : "";

export default () => (
  <Formik
    initialValues={{
      name: "",
      email: "",
      message: "",
      sentFromUrl: url,
    }}
    onSubmit={(values, actions) => {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact-form", ...values }),
      })
        .then(() => {
          Swal.fire({
            title: "Thank you",
            text: "We've got your message",
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonColor: "#041dd7",
          });
          actions.resetForm();
        })
        .catch(() => {
          Swal.fire({
            title: "Something went wrong",
            text: "Sorry, it looks like something went wrong so we didn't receive your message. Please try again later.",
            icon: "error",
            confirmButtonText: "OK",
            confirmButtonColor: "#041dd7",
          });
        })
        .finally(() => actions.setSubmitting(false));
    }}
    validate={(values) => {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      const errors = {};
      if (!values.name) {
        errors.name = "Please enter your name";
      }
      if (!values.email || !emailRegex.test(values.email)) {
        errors.email = "Please enter a valid email address";
      }
      if (!values.message) {
        errors.message = "Please enter your message";
      }
      return errors;
    }}
  >
    {() => (
      <Form name="contact-form" data-netlify={true}>
        <FlexWrapper>
          <div style={{ textAlign: "center", width: "100%" }}>
            <FormLabel htmlFor="name">Name: </FormLabel>
            <FormInput id="name" name="name" aria-labelledby="name" />

            <FormErrorMessage name="name" />
          </div>
          <span style={{ width: "5em" }}></span>
          <div style={{ textAlign: "center", width: "100%" }}>
            <FormLabel htmlFor="email">Email: </FormLabel>
            <FormInput id="email" name="email" aria-labelledby="email" />

            <FormErrorMessage name="email" />
          </div>
        </FlexWrapper>

        <div style={{ textAlign: "center", width: "100%" }}>
          <FormLabel htmlFor="message">Message: </FormLabel>
          <FormInput
            name="message"
            component="textarea"
            style={{ height: "5em" }}
            aria-labelledby="message"
            id="message"
          />

          <FormErrorMessage name="message" />
        </div>
        <div style={{ textAlign: "center", width: "100%" }}>
          <Field type="hidden" name="sentFromUrl" value={url}></Field>
          <FormButton type="submit">Send message</FormButton>
        </div>
      </Form>
    )}
  </Formik>
);
